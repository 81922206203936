<template>
  <div class="login-panel">
    <div class="login-info-box">
      <h2>Have an account?</h2>
      <p>Lorem ipsum dolor sit amet</p>
      <label id="label-register" for="log-reg-show">Login</label>
      <input
        type="radio"
        name="active-log-panel"
        id="log-reg-show"
        checked="checked"
      />
    </div>
    <div class="register-info-box">
      <h2>I Lost my password?</h2>
      <p>
        Don't worry, Resetting your password is easy, just tell us the email
        address by click link below.
      </p>
      <label id="label-login" for="log-login-show">Continue</label>
      <input type="radio" name="active-log-panel" id="log-login-show" />
    </div>
    <div class="white-panel">
      <!--<img src="../../assets/images/logo.png"/>-->
      <img src="../../assets/img/logo.svg" class="img-logo" />
      <div class="login-show" v-if="enable_form == 'auth'">
        <h2>LOGIN</h2>
        <small class="text-secondary">Please enter username & password.</small>
        <br /><br />
        <form @submit="SubmitLogin">
          <div class="form-group">
            <label>Privileges Group Access</label>
            <select class="form-control" v-model="model.id_privileges">
              <option value="">-- Choose One --</option>
              <option value="10">Root</option>
              <option value="18">Control Report</option>
              <option value="14">Client Station</option>
              <option value="11">Operation Manager</option>
              <option value="12">Supervisor</option>
              <option value="13">Leader</option>
              <option value="15">Hrd</option>
              <!-- last request revisi dituker, sales = 17 dan sales manager = 16 -->
              <option value="16">Sales Manager</option>
              <option value="17">Sales</option>
              <option value="19">Admin (Executive)</option>
              <option value="20">Branch Manager</option>
            </select>
            <small class="form-msg text-danger" v-if="error.id_privileges">{{
              error.id_privileges[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Email Address</label>
            <input
              type="email"
              v-model="model.email"
              class="form-control input_user"
              value=""
              placeholder="...."
            />
            <small class="form-msg text-danger" v-if="error.email">{{
              error.email[0]
            }}</small>
          </div>
          <div class="form-group">
            <label>Password</label>
            <input
              type="password"
              v-model="model.password"
              class="form-control input_pass"
              value=""
              placeholder="...."
            />
            <small class="form-msg text-danger" v-if="error.password">{{
              error.password[0]
            }}</small>
          </div>
          <button type="submit" class="btn btn-primary btn-block">
            <vue-loaders-ball-beat
              color="white"
              scale=".35"
              v-if="$store.state.loading.status"
            ></vue-loaders-ball-beat>
            <span v-else>CONTINUE </span>
          </button>
        </form>
      </div>
      <div class="login-show" v-if="enable_form == 'branch'">
        <div class="form-group">
          <span class="text-secondary">BRANCH STATION</span>
          <p class="">
            Branch options can change the data transfer between one branch and
            another
            <br />by selecting one of the following, all contract data,
            employee, client and others can be displayed.
          </p>
          <select class="form-control" v-model="model.index_branch">
            <option value="">-- Choose One --</option>
            <option
              v-for="(items, idx) in model_branch"
              :key="idx"
              :value="idx"
            >
              {{ items.name }}
            </option>
          </select>
          <small class="text-danger" v-if="msg_validate_submit !== ''">{{
            msg_validate_submit
          }}</small>
        </div>
        <button
          type="submit"
          class="btn btn-primary btn-block"
          @click="ContinueLogin()"
        >
          <vue-loaders-ball-beat
            color="white"
            scale=".35"
            v-if="$store.state.loading.status"
          ></vue-loaders-ball-beat>
          <span v-else>CONTINUE LOGIN </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
// import Password from 'vue-password-strength-meter';
// import axios from 'axios';

export default {
  components: {
    // Password
  },
  data() {
    return {
      isEnableFormReset: false,
      isEnableForgot: false,
      isEnableVerificationCode: false,
      enable_form: "auth",
      model_privileges: [],
      model: {
        email: "",
        password: "",
        id_privileges: "",
        index_branch: "",
        password_confirmation: "",
        code1: "",
        code2: "",
        code3: "",
        code4: "",
      },
      msg_validate_submit: "",

      model_branch: [],
      model_client_access: [],
      model_auth: {},
    };
  },

  computed: {
    ...mapState("authenticate", {
      error: (state) => state.error,
    }),
  },

  methods: {
    async ContinueLogin(initial) {
      // console.log(this.model_branch);
      if (!initial) {
        if (this.model.index_branch !== "") {
          let model = this.model_branch[this.model.index_branch];
          localStorage.setItem("userBranchId", model.id);
          localStorage.setItem("userBranchName", model.name);
          localStorage.setItem(
            "userBranchData",
            JSON.stringify(this.model_branch)
          );
          localStorage.setItem("data_regencies", model.group_regencies);
          localStorage.setItem("id_provinces", model.id_provinces);
          localStorage.setItem("provinces_name", model.nama_provinsi);
        } else {
          this.msg_validate_submit = "Please choose branch!";
          return;
        }
      }

      if (initial == "user_client") {
        // console.log(this.model.index_branch);

        let model = this.model_branch[0];
        localStorage.setItem("userBranchId", model.id);
        localStorage.setItem("userBranchName", model.name);
        localStorage.setItem("data_regencies", model.group_regencies);
        localStorage.setItem("id_provinces", model.id_provinces);
        localStorage.setItem("provinces_name", model.nama_provinsi);

        localStorage.setItem(
          "userBranchData",
          JSON.stringify(this.model_branch)
        );

        localStorage.setItem(
          "user-client",
          JSON.stringify(this.model_client_access)
        );

        localStorage.setItem(
          "user-clien_id",
          this.model_client_access[0].client_id
        );
      }
      // console.log("user_client ga ke log")
      localStorage.setItem("user-login", "true");
      localStorage.setItem("app-session", this.model_auth.app_session);
      localStorage.setItem("userid", this.model_auth.userid);
      localStorage.setItem("jwt-token", this.model_auth.token);
      window.location.reload();
    },
    InitialInput(next_id, val) {
      if (val !== "") {
        document.getElementById(next_id).focus();
      }
    },

    async SubmitLogin(e) {
      e.preventDefault();
      let res = await this.$store.dispatch("authenticate/login", this.model);
      if (res) {
        this.model_auth = res.auth;
        this.model_branch = res.data_branch;

        if (res.form == "direct") {
          this.ContinueLogin("direct");
        } else {
          if (res.form == "user_client") {
            this.model_client_access = res.client_access;
            this.ContinueLogin("user_client");
          } else {
            this.enable_form = res.form;
          }
        }
      }
    },
    async NewPassword(e) {
      e.preventDefault();
      let res = await this.$store.dispatch("authenticate/reset", this.model);
      if (res && res.result == "true") {
        this.$store.dispatch("authenticate/login", this.model);
      }
    },
    async RecoveryPassword(e) {
      e.preventDefault();
      if (!this.isEnableVerificationCode) {
        let res = await this.$store.dispatch("authenticate/forgot", this.model);
        if (res && res.result == "true") {
          alert(res.msg);
          this.isEnableVerificationCode = true;
          setTimeout(() => {
            document.getElementById("code1").focus();
          }, 300);
        }
      } else {
        let res = await this.$store.dispatch("authenticate/check", this.model);
        if (res && res.result == "true") {
          this.isEnableFormReset = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-panel {
  position: relative;
  top: 150px;
  width: 70%;
  left: 0;
  margin: auto;
  height: 400px;
  color: #b8b8b8;
  background-color: #0665c7;
  input[type="radio"] {
    position: relative;
    display: none;
  }
  #label-login,
  #label-register {
    border: 1px solid #d8d8d8;
    padding: 5px 5px;
    width: 150px;
    display: block;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
  }

  .login-info-box {
    width: 30%;
    padding: 0 50px;
    top: 20%;
    left: 0;
    position: absolute;
    text-align: left;
  }
  .register-info-box {
    width: 30%;
    padding: 0 50px;
    top: 20%;
    right: 0;
    position: absolute;
    text-align: left;
    color: #fff;
  }
  .right-log {
    right: 50px !important;
  }
  .login-show {
    margin-top: 80px;
  }
  .login-show,
  .register-show {
    z-index: 1;
    transition: 0.3s ease-in-out;
    color: #242424;
    text-align: left;
    padding: 50px;
    padding-top: 10px;
  }
}
.white-panel {
  background-color: rgba(255, 255, 255, 1);
  height: 500px;
  position: absolute;
  top: -50px;
  width: 50%;
  right: calc(50% - 50px);
  transition: 0.3s ease-in-out;
  z-index: 0;
  box-shadow: 0 0 15px 9px #00000096;
  img {
    width: 120px;
    float: left;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 20px;
  }
}
</style>
