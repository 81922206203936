import Vue from "vue";
import Router from "vue-router";
import store from "@/modules/index";
import $ from "jquery";

function init_table_drag() {
  var mx = 0;
  $(".table-cover").on({
    mousemove: function (e) {
      var mx2 = e.pageX - this.offsetLeft;
      if (mx) this.scrollLeft = this.sx + mx - mx2;
    },
    mousedown: function (e) {
      this.sx = this.scrollLeft;
      mx = e.pageX - this.offsetLeft;
    },
  });

  $(document).on("mouseup", function () {
    mx = 0;
  });
}

// Block Page
import BlockPage from "@/application/Auth/Block.vue";
// Home
import Dashboard from "@/application/Home/Dashboard.vue";
// Profile
import ProfileData from "@/application/Profile/Data.vue";
// Privileges
import PrivilegesData from "@/application/Privileges/Data.vue";
import PrivilegesStore from "@/application/Privileges/Store.vue";
import PrivilegesUpdate from "@/application/Privileges/Update.vue";
// User Data
import UserData from "@/application/User/Data.vue";
import UserStore from "@/application/User/Store.vue";
import UserUpdate from "@/application/User/Update.vue";
// Employee
import EmployeeData from "@/application/Employment/Data";
import EmployeeStore from "@/application/Employment/Store";
import EmployeeUpdate from "@/application/Employment/Update";
// Division
import HRDJobPosition from "@/application/Hrd/JobPosition/Master";
// Pospoint
import BranchData from "@/application/Branch/Data";
import BranchStore from "@/application/Branch/Store";
import BranchUpdate from "@/application/Branch/Update";

// Job Contract
import JobContractMasterData from "@/application/JobContract/Master/Data";
import JobContractMasterStore from "@/application/JobContract/Master/Store";
import JobContractMasterEdit from "@/application/JobContract/Master/Edit";
import JobContractMasterRenewal from "@/application/JobContract/Master/Renewal";

import JobContractAssignOfficerData from "@/application/JobContract/AssignOfficer";
import JobContractShiftWork from "@/application/JobContract/ShiftWork";
import JobContractShiftWork_V2 from "@/application/JobContract/ShiftWork_V2";
import JobContractTask from "@/application/JobContract/JobTask";
import JobTaskSection from "@/application/JobContract/JobTaskSection";
import PrintQrCode from "@/application/JobContract/PrintQrCode";
// Client
import ClientBaseMasterData from "@/application/ClientBase/Master/Data";
import ClientBaseStoreData from "@/application/ClientBase/Master/Store";
import ClientBaseUpdateData from "@/application/ClientBase/Master/Update";
// Configuration - Calendar
import Calendar from "@/application/Configuration/Calendar";
// Report
import ReportAttendanceData from "@/application/Report/Attendance/Master";
import ReportAttendanceDetail from "@/application/Report/Attendance/Detail";
import ReportAttendanceDetail_V2 from "@/application/Report/Attendance/Detail_V2";

import ReportChecklistArea from "@/application/Report/ChecklistArea/Master";
import ReportChecklistDetail from "@/application/Report/ChecklistArea/Detail";
import ReportSummary from "@/application/Report/Summary/Master";
import ReportSummaryDetail from "@/application/Report/Summary/Detail";
import GenerateReport from "@/application/Report/Generate/Index";
import GenerateReportCreate from "@/application/Report/Generate/Create";
import GenerateReportDetail from "@/application/Report/Generate/Detail";

// Notificaton
import NotificationMessage from "@/application/Notification/Message";
import NotificationSound from "@/application/Notification/Sound";
import EngineCron from "@/application/Notification/Engine";

Vue.use(Router);
const guard = function (to, from, next) {
  if (
    localStorage.getItem("jwt-token") &&
    localStorage.getItem("app-session") == store.state.app_session
  ) {
    var action = "";
    switch (to.path) {
      // Dashboard
      case "/":
        action = "view";
        store.state.app = {
          mkey: "dashboard",
          title: "",
          icon: "ti-home",
        };
        break;

      // HRD - Employee
      case "/employee/data":
        action = "view";
        store.state.app = {
          mkey: "hrd.employee",
          title: "Employee",
          icon: "ti-monitor",
          breadcrumb: [{ name: "Master Employee / Officer" }],
        };
        break;
      case "/employee/store":
        action = "insert";
        store.state.app = {
          mkey: "hrd.employee",
          title: "Employee",
          icon: "ti-monitor",
          breadcrumb: [
            { link: "employee/data", name: "Master Data" },
            { name: "Create" },
          ],
        };
        break;
      case "/employee/update":
        action = "insert";
        store.state.app = {
          mkey: "hrd.employee",
          title: "Employee",
          icon: "ti-monitor",
          breadcrumb: [
            { link: "employee/data", name: "Master Data" },
            { name: "Update" },
          ],
        };
        break;

      // Job Position
      case "/job-position/data":
        action = "view";
        store.state.app = {
          mkey: "hrd.job-position",
          title: "Job Position",
          icon: "",
          breadcrumb: [{ name: "Master Data" }],
        };
        break;

      // Job Contract
      case "/jobcon/contract-data":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [{ name: "Master Data" }],
        };
        break;
      case "/jobcon/contract-store":
        action = "insert";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Create" },
          ],
        };
        break;
      case "/jobcon/contract-renewal":
        action = "renewal";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract Renewal",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Renewal" },
          ],
        };
        break;
      case "/jobcon/contract-edit":
        action = "renewal";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract Edit",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Edit" },
          ],
        };
        break;
      case "/jobcon/shift":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Assign Shift Work" },
          ],
        };
        break;

      case "/jobcon/shift-v2":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Assign Shift Work V2" },
          ],
        };
        break;

      case "/jobcon/task":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Assign Job Task" },
          ],
        };
        break;
      case "/jobcon/task-section":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract Section Task",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Assign Job Task New" },
          ],
        };
        break;
      case "/jobcon/print-qrcode":
        action = "print_qrcode";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job ",
          icon: "",
        };
        break;
      case "/jobcon/contract-assign":
        action = "view";
        store.state.app = {
          mkey: "jobcon.contract",
          title: "Job Contract",
          icon: "",
          breadcrumb: [
            { link: "jobcon/contract-data", name: "Master Data" },
            { name: "Assign Officer" },
          ],
        };
        break;

      // Client
      case "/client/data":
        action = "view";
        store.state.app = {
          mkey: "jobcon.client",
          title: "Contract & Client",
          icon: "ti-table",
          breadcrumb: [{ name: "Client Base" }],
        };
        break;
      case "/client/store":
        action = "insert";
        store.state.app = {
          mkey: "jobcon.client",
          title: "Contract & Client",
          icon: "ti-table",
          breadcrumb: [
            { link: "client/data", name: "Client Base" },
            { name: "Create" },
          ],
        };
        break;
      case "/client/update":
        action = "update";
        store.state.app = {
          mkey: "jobcon.client",
          title: "Contract & Client",
          icon: "ti-table",
          breadcrumb: [
            { link: "client/data", name: "Cient Base" },
            { name: "Update" },
          ],
        };
        break;

      // Calendar
      case "/config/calendar":
        action = "view";
        store.state.app = {
          mkey: "settings.calendar",
          title: "Configuration",
          icon: "ti-calendar",
          breadcrumb: [{ name: "Calendar" }],
        };
        break;

      //  Privileges Group Level
      case "/privileges/data":
        action = "view";
        store.state.app = {
          mkey: "manage_user.privileges_group_level",
          title: "Manage User",
          icon: "ti-user",
          breadcrumb: [{ name: "Privileges Group Level" }],
        };
        break;
      case "/privileges/store":
        action = "insert";
        store.state.app = {
          mkey: "manage_user.privileges_group_level",
          title: "Manage User",
          icon: "ti ti-user",
          breadcrumb: [
            { link: "privileges/data", name: "Privileges Group Level" },
            { name: "Create" },
          ],
        };
        break;
      case "/privileges/update":
        action = "update";
        store.state.app = {
          mkey: "manage_user.privileges_group_level",
          title: "Manage User",
          icon: "ti ti-user",
          breadcrumb: [
            { link: "privileges/data", name: "Privileges Group Level" },
            { name: "Update" },
          ],
        };
        break;
      // Application User
      case "/user/data":
        action = "view";
        store.state.app = {
          mkey: "manage_user.application_user",
          title: "Manage User",
          icon: "ti-user",
          breadcrumb: [{ name: "Application User" }],
        };
        break;
      case "/user/store":
        action = "insert";
        store.state.app = {
          mkey: "manage_user.application_user",
          title: "Manage User",
          icon: "ti ti-user",
          breadcrumb: [
            { link: "user/data", name: "Application User" },
            { name: "Create" },
          ],
        };
        break;
      case "/user/update":
        action = "update";
        store.state.app = {
          mkey: "manage_user.application_user",
          title: "Manage User",
          icon: "ti ti-user",
          breadcrumb: [
            { link: "user/data", name: "Application User" },
            { name: "Create" },
          ],
        };
        break;

      // Report
      case "/report/attendance-data":
        action = "view";
        store.state.app = {
          mkey: "report.attendance",
          title: "Report",
          icon: "",
          breadcrumb: [{ name: "Attendance" }],
        };
        break;
      case "/report/attendance-detail":
        action = "detail";
        store.state.app = {
          mkey: "report.attendance",
          title: "Report",
          icon: "",
          breadcrumb: [
            { link: "report/attendance-data", name: "Attendance" },
            { name: "Detail" },
          ],
        };
        break;
      case "/report/checklist-area-data":
        action = "view";
        store.state.app = {
          mkey: "report.checklist_area",
          title: "Report",
          icon: "",
          breadcrumb: [
            { link: "report/checklist-area-data", name: "Checklist Area" },
          ],
        };
        break;

      case "/report/checklist-area-detail":
        action = "detail";
        store.state.app = {
          mkey: "report.checklist_area",
          title: "Report",
          icon: "",
          breadcrumb: [
            { link: "report/checklist-area-data", name: "Checklist Area" },
            { name: "Detail" },
          ],
        };
        break;

      case "/report/summary-data":
        action = "detail";
        store.state.app = {
          mkey: "report.summary",
          title: "Report Summary",
          icon: "",
          breadcrumb: [{ link: "report/summary-data", name: "Summary Data" }],
        };
        break;

      case "/report/summary-detail":
        action = "detail";
        store.state.app = {
          mkey: "report.summary",
          title: "Report",
          icon: "",
          breadcrumb: [
            { link: "report/summary-data", name: "Summary Data" },
            { name: "Detail" },
          ],
        };
        break;

      case "/report/generate-report":
        action = "view";
        store.state.app = {
          mkey: "report.generate_report",
          title: "Generate Report",
          icon: "",
          breadcrumb: [{ name: "List" }],
        };
        break;

      case "/report/generate-report/create":
        action = "insert";
        store.state.app = {
          mkey: "report.generate_report_create",
          title: "Generate Create",
          icon: "",
          breadcrumb: [
            { link: "report/generate-report", name: "List" },
            { name: "Create" },
          ],
        };
        break;
      case "/report/generate-report/detail":
        action = "update";
        store.state.app = {
          mkey: "report.generate_report_detail",
          title: "Generate Report",
          icon: "",
          breadcrumb: [
            { link: "report/generate-report", name: "List" },
            { name: "Detail" },
          ],
        };
        break;

      // Notification
      case "/notification/sound":
        action = "view";
        store.state.app = {
          mkey: "settings.notification",
          title: "Notification",
          icon: "ti-email",
          breadcrumb: [{ name: "Notification" }],
        };
        break;
      case "/notification/message":
        action = "view";
        store.state.app = {
          mkey: "settings.message",
          title: "Message",
          icon: "ti-email",
          breadcrumb: [{ name: "Inbox" }],
        };
        break;
      case "/notification/cron":
        action = "view";
        store.state.app = {
          mkey: "settings.cron",
          title: "Engine Cron",
          icon: "ti-email",
          breadcrumb: [{ name: "Engine Cron" }],
        };
        break;

      // Branch Station
      case "/branch/data":
        action = "view";
        store.state.app = {
          mkey: "hrd.branch",
          title: "Human Resource",
          icon: "ti-email",
          breadcrumb: [{ name: "Branch Station Data" }],
        };
        break;
      case "/branch/store":
        action = "insert";
        store.state.app = {
          mkey: "hrd.branch",
          title: "Human Resource",
          icon: "ti-email",
          breadcrumb: [
            { link: "branch/data", name: "Branch Station" },
            { name: "Create" },
          ],
        };
        break;
      case "/branch/update":
        action = "update";
        store.state.app = {
          mkey: "hrd.branch",
          title: "Human Resource",
          icon: "ti-email",
          breadcrumb: [
            { link: "branch/data", name: "Branch Station" },
            { name: "Update" },
          ],
        };
        break;
    }

    store.dispatch("crud/normalize");
    store.dispatch("mreg/actionModules", { mkey: store.state.app.mkey });
    let access = store.state.app.access;

    next();
    setTimeout(() => {
      // table
      init_table_drag();
    }, 500);
  } else {
    store.state.alert = {
      status: true,
      type: "danger",
      msg: "Opps Sorry, Your login session is expired!",
      close_redirect: "/",
    };
  }
};

const router = new Router({
  routes: [
    {
      path: "/block-page",
      name: "block-page",
      component: BlockPage,
    },
    {
      path: "/",
      name: "/",
      component: Dashboard,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/profile/data",
      name: "profile/data",
      component: ProfileData,
      props: true,
    },
    {
      path: "/job-position/data",
      name: "job-position/data",
      component: HRDJobPosition,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/employee/data",
      name: "employee/data",
      component: EmployeeData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/employee/store",
      name: "employee/store",
      component: EmployeeStore,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/employee/update",
      name: "employee/update",
      component: EmployeeUpdate,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    // Branch Station
    {
      path: "/branch/data",
      name: "branch/data",
      component: BranchData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/branch/store",
      name: "branch/store",
      component: BranchStore,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/branch/update",
      name: "branch/update",
      component: BranchUpdate,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // Job Contract
    {
      path: "/jobcon/contract-data",
      name: "jobcon/contract-data",
      component: JobContractMasterData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/contract-store",
      name: "jobcon/contract-store",
      component: JobContractMasterStore,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/contract-renewal",
      name: "jobcon/contract-renewal",
      component: JobContractMasterRenewal,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/contract-edit",
      name: "jobcon/contract-edit",
      component: JobContractMasterEdit,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/shift",
      name: "jobcon/shift",
      component: JobContractShiftWork,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/shift-v2",
      name: "jobcon/shift-v2",
      component: JobContractShiftWork_V2,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    {
      path: "/jobcon/task",
      name: "jobcon/task",
      component: JobContractTask,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/task-section",
      name: "jobcon/task-section",
      component: JobTaskSection,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/print-qrcode",
      name: "jobcon/print-qrcode",
      component: PrintQrCode,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/jobcon/contract-assign",
      name: "jobcon/contract-assign",
      component: JobContractAssignOfficerData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // Client
    {
      path: "/client/data",
      name: "client/data",
      component: ClientBaseMasterData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/client/store",
      name: "client/store",
      component: ClientBaseStoreData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/client/update",
      name: "client/update",
      component: ClientBaseUpdateData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // Configuration - Calendar
    {
      path: "/config/calendar",
      name: "config/calendar",
      component: Calendar,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // User
    {
      path: "/user/data",
      name: "user/data",
      component: UserData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/user/store",
      name: "user/store",
      component: UserStore,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/user/update",
      name: "user/update",
      component: UserUpdate,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // Privileges
    {
      path: "/privileges/data",
      name: "privileges/data",
      component: PrivilegesData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/privileges/store",
      name: "privileges/store",
      component: PrivilegesStore,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/privileges/update",
      name: "privileges/update",
      component: PrivilegesUpdate,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    // Report
    {
      path: "/report/attendance-data",
      name: "report/attendance-data",
      component: ReportAttendanceData,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/report/attendance-detail",
      name: "report/attendance-detail",
      component: ReportAttendanceDetail,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/report/attendance-detail-v2",
      name: "report/attendance-detail-v2",
      component: ReportAttendanceDetail_V2,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/report/checklist-area-data",
      name: "report/checklist-area-data",
      component: ReportChecklistArea,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/report/checklist-area-detail",
      name: "report/checklist-area-detail",
      component: ReportChecklistDetail,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },

    {
      path: "/report/summary-data",
      name: "report/summary-data",
      component: ReportSummary,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      }, // ---- Add Rudy ---- //
    },
    {
      path: "/report/summary-detail",
      name: "report/summary-detail",
      component: ReportSummaryDetail,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      }, // ---- Add Rudy ---- //
    },
    {
      path: "/report/generate-report",
      name: "report/generate-report",
      component: GenerateReport,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      }, // ---- Add Rudy ---- //
    },
    {
      path: "/report/generate-report/create",
      name: "report/generate-report/create",
      component: GenerateReportCreate,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      }, // ---- Add Rudy ---- //
    },
    {
      path: "/report/generate-report/detail",
      name: "report/generate-report/detail",
      component: GenerateReportDetail,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      }, // ---- Add Rudy ---- //
    },
    // end Report

    // Notification
    {
      path: "/notification/sound",
      name: "notification/sound",
      component: NotificationSound,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/notification/message",
      name: "notification/message",
      component: NotificationMessage,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
    {
      path: "/notification/cron",
      name: "notification/cron",
      component: EngineCron,
      props: true,
      beforeEnter: (to, from, next) => {
        guard(to, from, next);
      },
    },
  ],
});

export default router;
