<template>
  <div class="col-md-12 p-0">
    <PopupHistorySendMail
      v-on:exit="closeModal"
      :id_customreport="id_customreport"
      v-if="isEnablePopup == 'list-history-send'"
    />
    <PopupSendCustomer
      v-on:exit="closeModal"
      :data_customer="dataCustomer"
      v-on:applyData="sendEmailToCustomer"
      v-if="isEnablePopup == 'modal-send-customer'"
    />
    <div class="card">
      <div class="card-body">
        <div class="table-header mb-4">
          <div class="row">
            <div class="col-md-6">
              <h5>
                List Generate Custom Report <br />
                <small
                  >Total Records : <b>{{ result_report_total }}</b></small
                >
              </h5>
            </div>
            <div class="col-md-6">
              <!-- old style -->
              <!-- <router-link
                style="width: 200px; float: right; margin-left: 10px"
                :to="{ name: 'report/generate-report/create' }"
                class="btn btn-primary"
                v-if="$store.state.app.access.includes('insert')"
              >
                <i class="ti ti-plus"></i> Create New</router-link
              >
              <SelectBranch
                style="width: 200px; float: right"
                v-if="isRoot"
                v-on:processBranch="processBranch"
              /> -->
              <!-- end old style -->
              <div class="row">
                <div class="col-md-12">
                  <div class="group-search pull-right">
                    <a class="btn" @click="isActiveSearch()"
                      ><i
                        :class="
                          isEnableTools == false
                            ? 'ti ti-search'
                            : 'ti ti-close'
                        "
                      ></i
                    ></a>
                    <div class="select" v-if="isEnableTools">
                      <select
                        v-model="meta_data.skey"
                        @change="meta_data.sval = ''"
                      >
                        <option value="">-- Choose Field --</option>
                        <option value="contract_number">Contract Number</option>
                        <option value="client_name">Client Name</option>
                      </select>
                    </div>
                    <div class="group" v-if="isEnableTools">
                      <input
                        type="date"
                        v-model="meta_data.sval"
                        v-if="
                          meta_data.skey == 'created_at' ||
                          meta_data.skey == 'updated_at'
                        "
                      />
                      <input
                        type="text"
                        v-model="meta_data.sval"
                        placeholder="Enter Keyword ...."
                        v-else
                      />
                      <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
                    </div>

                    <router-link
                      :to="{ name: 'report/generate-report/create' }"
                      class="btn btn-primary"
                      v-if="$store.state.app.access.includes('insert')"
                    >
                      <i class="ti ti-plus"></i> Create New</router-link
                    >

                    <SelectBranch
                      v-if="isRoot"
                      v-on:processBranch="processBranch"
                    />
                  </div>
                </div>
              </div>

              <!-- <div style="position: absolute; right: 28%; bottom: 44%">
                <SelectBranch
                  v-if="isRoot"
                  v-on:processBranch="processBranch"
                />
              </div> -->
            </div>
          </div>
          <!-- <div v-if="$store.state.loading.status">
            <div class="row">
              <div class="col-md-6">
                <ListLoader
                  :speed="1"
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                ></ListLoader>
              </div>
              <div class="col-md-6">
                <ListLoader
                  :speed="1"
                  primaryColor="#f3f3f3"
                  secondaryColor="#ecebeb"
                ></ListLoader>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-md-6">
              <h5>
                List Generate Custom Report <br />
                <small
                  >Total Records : <b>{{ result_report.total }}</b></small
                >
              </h5>
            </div>
            <div class="col-md-6">
              <div class="group-search pull-right">
                <a class="btn" @click="isActiveSearch()"
                  ><i
                    :class="
                      isEnableTools == false ? 'ti ti-search' : 'ti ti-close'
                    "
                  ></i
                ></a>
                <div class="select" v-if="isEnableTools">
                  <select
                    v-model="meta_data.skey"
                    @change="meta_data.sval = ''"
                  >
                    <option value="">-- Choose Field --</option>
                    <option value="contract_number">Contract Number</option>
                    <option value="client_name">Client Name</option>
                  </select>
                </div>
                <div class="group" v-if="isEnableTools">
                  <input
                    type="date"
                    v-model="meta_data.sval"
                    v-if="
                      meta_data.skey == 'created_at' ||
                      meta_data.skey == 'updated_at'
                    "
                  />
                  <input
                    type="text"
                    v-model="meta_data.sval"
                    placeholder="Enter Keyword ...."
                    v-else
                  />
                  <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
                </div>

                <router-link
                  :to="{ name: 'report/generate-report/create' }"
                  class="btn"
                  v-if="$store.state.app.access.includes('insert')"
                >
                  <i class="ti ti-plus"></i> Create New</router-link
                >
              </div>

              <div style="position: absolute; right: 28%; bottom: 44%">
                <SelectBranch
                  v-if="isRoot"
                  v-on:processBranch="processBranch"
                />
              </div>
            </div>
          </div> -->
        </div>
        <div class="row p-4" v-if="$store.state.loading.status">
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
          <div class="col-md-6">
            <ListLoader
              :speed="1"
              primaryColor="#f3f3f3"
              secondaryColor="#ecebeb"
            ></ListLoader>
          </div>
        </div>
        <div v-else class="table-cover">
          <table class="table-hover">
            <thead>
              <tr>
                <th>Action</th>
                <th>Contract Number</th>
                <th>Client Name</th>
                <th>Contract Period</th>
                <th>Status</th>
                <th>Created By</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody
              v-if="result_report_total > 0 && result_report_data.length > 0"
            >
              <tr v-for="(item, idx) in result_report_data" :key="idx">
                <td align="center">
                  <a
                    href="javascript:;"
                    title="Send To Customer"
                    @click="OpenModalSendCustomer(item)"
                    class="btn-secondary btn-sm mr-2"
                    v-if="
                      $store.state.app.access.includes('update') &&
                      item.status == 'publish'
                    "
                  >
                    <i class="ti ti-share text-light"></i>
                  </a>

                  <a
                    href="javascript:;"
                    title="History Send Email"
                    @click="historySend(item.id)"
                    class="btn-primary btn-sm mr-2"
                    v-if="item.status == 'publish'"
                  >
                    <i class="ti ti-eye text-light"></i>
                  </a>

                  <a
                    title="Detail Report Generate"
                    href="javascript:;"
                    @click="ReportDetail(item.id)"
                    class="btn-info btn-sm mr-2"
                    v-if="$store.state.app.access.includes('detail')"
                  >
                    <i class="ti ti-pencil-alt text-light"></i>
                  </a>

                  <a
                    title="Hapus Report Generate"
                    href="javascript:;"
                    v-if="isRoot || $store.state.app.access.includes('update')"
                    @click="RemoveGenerateReport(item.id)"
                    class="btn-danger btn-sm mr-2"
                  >
                    <i class="ti ti-trash text-light"></i>
                  </a>
                </td>
                <td width="200">{{ item.contract_number }}</td>
                <td class="pr-3">
                  <b>{{ item.client_name }}</b
                  ><br />
                  <small class="text-secondary">
                    {{ item.client_address }}
                  </small>
                </td>
                <td width="170" class="pr-3">
                  <span class="badge badge-success">{{
                    item.contract_status
                  }}</span
                  ><br />
                  {{ item.contract_start_date }} - {{ item.contract_end_date }}
                </td>
                <td width="100" class="pr-3">
                  <span
                    v-if="item.status == 'draft'"
                    class="badge badge-warning"
                  >
                    {{ item.status }}
                  </span>
                  <span
                    v-if="item.status == 'publish'"
                    class="badge badge-success"
                  >
                    {{ item.status }}
                  </span>
                  <span
                    v-if="item.status == 'revisi'"
                    class="badge badge-danger"
                  >
                    {{ item.status }}
                  </span>
                  <br />
                  <span v-if="item.status == 'revisi'">
                    {{ item.note_revisi }}
                  </span>
                </td>
                <td class="pr-3">{{ item.user_name }}</td>
                <td class="pr-3">{{ item.created_at }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="7" align="center">Data Not Found</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="$store.state.loading.status"></div>
        <div v-else class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(result_report_last_page)"
            :page-range="3"
            :margin-pages="2"
            :click-handler="clickCallback"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MixServerDate from "../../../mixin/mix_serverdate.js";
import { mapState } from "vuex";
import router from "../../../router";
import axios from "axios";
import SelectBranch from "../../Branch/@SelectBranch";
import { ContentLoader, ListLoader, CodeLoader } from "vue-content-loader";
import PopupHistorySendMail from "./@PopupHistorySendMail.vue";
import PopupSendCustomer from "./@PopupSendCustomer.vue";
export default {
  mixins: [MixServerDate],
  data() {
    return {
      isEnablePopup: false,
      id_customreport: null,
      dataCustomer: null,
      meta_url: "report/generate",
      isEnableTools: false,
      meta_data: {
        okey: "created_at",
        oval: "desc",
        skey: "",
        sval: "",
      },
      result_report: null,
      result_report_data: [],
      result_report_total: 0,
      result_report_last_page: 0,
      branchId: "all",
      isClient: false,
      isRoot: false,
      currentPage: 1,
    };
  },
  components: {
    ContentLoader,
    ListLoader,
    CodeLoader,
    PopupHistorySendMail,
    PopupSendCustomer,
    SelectBranch,
  },
  computed: {
    ...mapState("crud", {
      msg_error: (state) => state.error,
    }),
  },
  created() {
    if (localStorage.getItem("userPrivilegesName") !== "root") {
      this.isClient =
        localStorage.getItem("userIdPrivileges") == 14 ? true : false;
      this.branchId = localStorage.getItem("userBranchId");
    } else {
      this.isRoot = true;
    }

    this.getDataReport();
  },
  methods: {
    clickCallback: function (pageNum) {
      this.currentPage = Number(pageNum);
      // console.log("clickCallback", this.currentPage);
      this.getDataReport();
    },
    processBranch(idBranch) {
      this.branchId = idBranch;
      // console.log(this.branchId);
      this.getDataReport();
    },
    async getDataReport() {
      console.log(this.meta_data);
      this.result_report = null;
      await axios
        .post(this.meta_url + "/list?page=" + this.currentPage, {
          filter: this.meta_data,
          branch_id: this.branchId,
        })
        .then((res) => {
          // this.result_report = res.data;
          this.result_report_data = res.data.data;
          this.result_report_total = res.data.total;
          this.result_report_last_page = res.data.last_page;
        })
        .catch((error) => {
          console.log(error.statusText);
        });
    },
    ReportDetail(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "report/generate-report/detail" });
    },
    async RemoveGenerateReport(id) {
      let text = "Apakah anda yakin !\nMenghapus data Report ini.";
      // console.log(this.meta_url);
      if (confirm(text) == true) {
        this.$store.state.loading.msg = "Loading ...";
        this.$store.state.loading.status = true;

        await axios
          .post(this.meta_url + "/delete", {
            id: id,
          })
          .then((res) => {
            let response = res.data;

            if (response.status) {
              this.getDataReport();
              this.$store.state.alert.status = true;
              this.$store.state.alert.type = "success";
              this.$store.state.alert.msg = response.message;
            } else {
              this.$store.state.alert.status = true;
              this.$store.state.alert.type = "trash";
              this.$store.state.alert.msg = response.message;
            }
          })
          .catch((error) => {
            console.log(error.statusText);
          })
          .finally(() => {
            setTimeout(() => {
              this.$store.state.loading.status = false;
            }, 1750);
          });
      }
      // console.log(id, text);
    },
    historySend(id) {
      this.id_customreport = id;
      this.isEnablePopup = "list-history-send";
    },
    closeModal() {
      this.isEnablePopup = false;
    },
    OpenModalSendCustomer(item) {
      this.dataCustomer = item;
      this.isEnablePopup = "modal-send-customer";
    },
    sendEmailToCustomer(data) {
      // console.log(data);
      if (data.file !== null) {
        this.SendReportToCustomerWithFile(data.id, data.email, data.file);
      } else {
        this.SendReportToCustomer(data.id);
      }
    },
    async SendReportToCustomerWithFile(id, email, file) {
      this.closeModal();
      this.$store.state.loading.msg = "Process send report ...";
      this.$store.state.loading.status = true;

      const formData = new FormData();
      formData.append("id", id);
      formData.append("email", email);
      formData.append("file", file);
      await axios
        .post(this.meta_url + "/send-to-customer-with-file", formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((res) => {
          const response = res.data;
          // console.log(response);
          if (response.result == "ok") {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.msg;
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.msg;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.alert.status = true;
          this.$store.state.alert.type = "trash";
          this.$store.state.alert.msg = "Failed Send mail to Customer ..";
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
          }, 1000);
        });
    },
    async SendReportToCustomer(id) {
      this.closeModal();
      let params = {
        id: id,
      };

      this.$store.state.loading.msg = "Process send report ...";
      this.$store.state.loading.status = true;

      await axios
        .post(this.meta_url + "/send-to-customer", params)
        .then((res) => {
          const response = res.data;
          if (response.result == "ok") {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "success";
            this.$store.state.alert.msg = response.msg;
          } else {
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = response.msg;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.state.alert.status = true;
          this.$store.state.alert.type = "trash";
          this.$store.state.alert.msg = "Failed Send mail to Customer ..";
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.state.loading.status = false;
          }, 1000);
        });
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },

    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      // this.tableQuery();
      this.getDataReport();
    },
  },
};
</script>
