<template>
  <div class="col-md-12 p-0">
    <!-- Popup  -->
    <PopupDetail v-if="isEnablePopup == 'detail'" v-on:exit="closePopup" />
    <PopupPerformance
      v-if="isEnablePopup == 'performance'"
      v-on:exit="closePopup"
    />
    <PopupUpdate v-if="isEnablePopup == 'update'" v-on:exit="closePopup" />
    <!-- Download / export excel -->
    <div class="popup popup-sm" v-if="isEnablePopup == 'download'">
      <div class="content">
        <small id="close-popup" @click="closePopup()"
          ><i class="ti ti-close"></i
        ></small>
        <h5 class="text-left">
          Download Report<br />
          <span class="text-secondary mr-4"
            >Please choose available file report format</span
          >
          <hr />
        </h5>
        <div class="text-right">
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="csv"
            name="Master Employee.xls"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-warning"></i> Format .CSV</a
            >
          </export-excel>
          <export-excel
            class="float-right mr-2"
            :data="download.data"
            :fields="download.fields"
            type="xls"
            name="Master Employee.xls"
            :title="download.title"
          >
            <a href="javascript:;" class="btn btn-light"
              ><i class="ti ti-file text-success"></i> Format .XLS</a
            >
          </export-excel>
        </div>
      </div>
    </div>
    <!-- Download / export excel -->
    <div class="popup popup-xl" v-if="isEnablePopup == 'modal-import-excel'">
      <div class="content">
        <small id="close-popup" @click="closePopup()"
          ><i class="ti ti-close"></i
        ></small>
        <h4 class="text-center">
          Format Import Excel Employee
          <br />
          <span class="text-secondary mr-4">
            Format Import Data ini akan menyesuaikan berdasarkan pilihan berikut
            :
          </span>
        </h4>

        <div class="row mt-5">
          <div class="col-md-6">
            <div class="form-group" style="padding: 5px 0">
              <label for="province">Propinsi</label>

              <input
                type="hidden"
                v-model="modal_import_filter.provinces_id"
                readonly
              />
              <input
                type="text"
                class="form-control"
                v-model="modal_import_filter.provinces_name"
                readonly
              />
            </div>

            <div class="form-group" style="padding: 5px 0">
              <label for="city">Kota/Kabupaten</label>
              <select
                class="form-control"
                v-model="modal_import_filter.city_id"
                @change="fetchKecamatanModal()"
              >
                <option value="" disabled>-- Choose One --</option>
                <option
                  v-for="(items, idx) in modal_import_filter.model_city"
                  :key="idx"
                  :value="items.id"
                >
                  {{ items.nama_kota }}
                </option>
              </select>
            </div>

            <div class="form-group" style="padding: 5px 0">
              <label for="distric">Kecamatan</label>
              <select
                class="form-control"
                v-model="modal_import_filter.district_id"
                :disabled="
                  modal_import_filter.model_districts.length > 0 ? false : true
                "
              >
                <option value="" disabled>-- Choose One --</option>
                <option
                  v-for="(items, idx) in modal_import_filter.model_districts"
                  :key="idx"
                  :value="items.id"
                >
                  {{ items.nama_kecamatan }}
                </option>
              </select>
              <br />
              <a
                v-if="modal_import_filter.district_id"
                @click="getSampleDownloadFormatEmployee()"
                class="btn btn-light"
                ><i class="ti ti-file text-success"></i> Sample Format
                Employee.xls</a
              >
            </div>
          </div>

          <div class="col-md-6">
            <h6 align="center">
              Upload Excel dengan Data dan Format seperti yang sudah di Download
            </h6>
            <div class="clearfix" style="height: 20px; clear: both"></div>
            <div class="text-center">
              <img
                class="img-rounded text-center"
                :src="'img/uploadfile.png'"
                title="Upload Excel"
              />

              <div class="clearfix" style="height: 20px; clear: both"></div>

              <div class="form-group">
                <div class="input-group input-group-file">
                  <span class="input-group-btn col-md-12">
                    <span
                      class="btn btn-success btn-file waves-effect waves-classic"
                    >
                      <input
                        type="file"
                        class="ml-2"
                        accept=".xlsx, .xls"
                        @change="processFile($event)"
                      />
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <!-- <div class="form-group" style="padding: 0;">
                    <label for="distric">Upload File Excel</label>
                    <input type="file" class="form-control" accept=".xlsx, .xls" >
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-header">
          <h5>
            Master Data <br />
            <small
              >Total Records :
              <b>{{ data_model.total | formatPrice }}</b></small
            >
          </h5>
          <div class="group-search pull-right">
            <a class="btn" @click="tableRefresh()"
              ><i class="ti ti-reload"></i
            ></a>
            <a class="btn" @click="isActiveSearch()"
              ><i
                :class="isEnableTools == false ? 'ti ti-search' : 'ti ti-close'"
              ></i
            ></a>
            <div class="select" v-if="isEnableTools">
              <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                <option value="">-- Pilih Kolom --</option>
                <option value="employee.nik">NIK</option>
                <option value="employee.firstname">Firstname</option>
                <option value="employee.lastname">Lastname</option>
                <option value="employee.email">Email</option>
                <option value="employee.phone_number">Phone Number</option>
                <option value="employee.identify_number">
                  Identify Card Number
                </option>
              </select>
            </div>
            <div class="group" v-if="isEnableTools">
              <input
                type="date"
                v-model="meta_data.sval"
                v-if="
                  meta_data.skey == 'created_at' ||
                  meta_data.skey == 'updated_at'
                "
              />
              <input
                type="search"
                v-model="meta_data.sval"
                placeholder="Enter Keyword ...."
                v-else
              />
              <a class="btn" @click="tableSearch()">SEARCH DATA </a>
            </div>
            <a
              href="javascript:;"
              class="btn btn-success mr-2"
              @click="DownloadData()"
              ><i class="ti ti-cloud-down"></i> Download</a
            >
            <router-link
              :to="{ name: 'employee/store' }"
              class="btn btn-primary"
              v-if="$store.state.app.access.includes('insert')"
              ><i class="ti ti-plus"></i> Create New</router-link
            >
            <a
              v-if="!isClient"
              href="javascript:;"
              class="btn btn-secondary mr-2"
              @click="modalImportExcel()"
              ><i class="ti ti-cloud-down"></i> Import Excel</a
            >
          </div>
          <div class="group-filter">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group float-left" v-if="isRoot">
                  <label class="text-secondary">Branch Station</label>
                  <SelectBranch v-on:processBranch="processBranch" />
                </div>
              </div>

              <div class="col-md-7" v-if="!isClient">
                <div class="form-group mr-2" style="width: 20%">
                  <label class="text-secondary">Activity Status</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.activity_status"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Status --</option>
                    <option value="active">Status - Active</option>
                    <option value="resign">Status - Resign</option>
                  </select>
                </div>
                <div class="form-group mr-2" style="width: 20%">
                  <label class="text-secondary">Join in Contract</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.join_contract"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Status --</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div class="form-group mr-2" style="width: 25%">
                  <label class="text-secondary">Job Position</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.job_position"
                    @change="tableQuery()"
                    :disabled="model_job_position ? false : true"
                  >
                    <option value="all">-- All Job Position --</option>
                    <option
                      v-for="(items, idx) in model_job_position"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.title }}
                    </option>
                  </select>
                </div>
                <div class="form-group mb-0" style="width: 25%">
                  <label class="text-secondary">Gender</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.gender"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Gender --</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              <div class="col-md-5" v-if="!isClient">
                <div class="form-group float-right" style="width: 30%">
                  <label class="text-secondary">Kecamatan</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.id_kecamatan"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Kecamatan --</option>
                    <option
                      v-for="(items, idx) in model_kecamatan"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_kecamatan }}
                    </option>
                  </select>
                </div>
                <div class="form-group mr-2 float-right" style="width: 30%">
                  <label class="text-secondary">Kota</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.id_kota"
                    @change="fetchKecamatan()"
                  >
                    <option value="all">-- All Kota --</option>
                    <option
                      v-for="(items, idx) in model_kota"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.name ? items.name : items.nama_kota }}
                    </option>
                  </select>
                </div>
                <div class="form-group mr-2 float-right" style="width: 30%">
                  <label class="text-secondary">Propinsi</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="provinces_name"
                    readonly
                    v-if="provinces_name !== ''"
                  />
                  <select
                    class="form-control"
                    v-model="meta_filter.id_propinsi"
                    @change="FilterProvinces()"
                    style="max-width: 200px"
                    v-else
                  >
                    <option value="all">-- All Propinsi --</option>
                    <option
                      v-for="(items, idx) in model_province"
                      :key="idx"
                      :value="items.id"
                    >
                      {{ items.nama_provinsi }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-cover" v-if="!$store.state.loading.status">
          <hr />
          <table class="table-hover" width="3100">
            <thead>
              <tr>
                <th v-if="JSON.parse($store.state.app.access).length > 1">
                  Action
                </th>
                <th class="text-center">Photo</th>
                <th
                  :class="
                    meta_data.okey == 'employee.activity_status' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.activity_status')"
                >
                  Activity Status
                </th>
                <th
                  :class="meta_data.okey == 'employee.nik' ? 'active' : ''"
                  v-on:click="tableOrdering('employee.nik')"
                >
                  Branch & (NIK)
                </th>
                <th
                  :class="meta_data.okey == 'client.name' ? 'active' : ''"
                  v-on:click="tableOrdering('client.name')"
                >
                  Join In Contract
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.firstname' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.firstname')"
                >
                  Complete Name
                </th>
                <th
                  :class="
                    meta_data.okey == 'job_position.title' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('job_position.title')"
                >
                  Job Position
                </th>
                <th
                  :class="meta_data.okey == 'employee.email' ? 'active' : ''"
                  v-on:click="tableOrdering('employee.email')"
                >
                  Email
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.phone_number' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.phone_number')"
                >
                  Phone Number
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.indentity_number'
                      ? 'active'
                      : ''
                  "
                  v-on:click="tableOrdering('employee.indentity_number')"
                >
                  Identify Card
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.nationality' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.nationality')"
                >
                  Nationality
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.marital_status' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.marital_status')"
                >
                  Marital Status
                </th>
                <th
                  :class="meta_data.okey == 'employee.gender' ? 'active' : ''"
                  v-on:click="tableOrdering('employee.gender')"
                >
                  Marital Status
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.birth_place' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.birth_place')"
                >
                  Birth Place
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.birth_date' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.birth_date')"
                >
                  Birth Date
                </th>
                <th
                  :class="
                    meta_data.okey == 'teritori_provinsi.nama_provinsi'
                      ? 'active'
                      : ''
                  "
                  v-on:click="tableOrdering('teritori_provinsi.nama_provinsi')"
                >
                  Propinsi
                </th>
                <th
                  :class="
                    meta_data.okey == 'teritori_kota.nama_kota' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('teritori_kota.nama_kota')"
                >
                  Kota
                </th>
                <th
                  :class="
                    meta_data.okey == 'teritori_kecamatan.nama_kecamatan'
                      ? 'active'
                      : ''
                  "
                  v-on:click="
                    tableOrdering('teritori_kecamatan.nama_kecamatan')
                  "
                >
                  Kecamatan
                </th>
                <th
                  :class="meta_data.okey == 'employee.address' ? 'active' : ''"
                  v-on:click="tableOrdering('employee.address')"
                >
                  Address
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.individual_skill'
                      ? 'active'
                      : ''
                  "
                  v-on:click="tableOrdering('employee.individual_skill')"
                >
                  Skill / Keterampilan
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.created_at' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.created_at')"
                >
                  Created Date
                </th>
                <th
                  :class="
                    meta_data.okey == 'employee.udpated_at' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('employee.udpated_at')"
                >
                  Updated Date
                </th>
              </tr>
            </thead>
            <tbody v-if="data_model.total > 0">
              <tr v-for="(items, index) in data_model.data" :key="index">
                <td v-if="JSON.parse($store.state.app.access).length > 1">
                  <div class="tools">
                    <a href="javascript:;">Tools</a>
                    <ul class="">
                      <li
                        v-if="$store.state.app.access.includes('information')"
                      >
                        <a href="javascript:;" @click="EmployeeDetail(items.id)"
                          ><i class="ti ti-info-alt text-primary"></i>
                          Information</a
                        >
                      </li>
                      <li
                        v-if="$store.state.app.access.includes('performance')"
                      >
                        <a
                          href="javascript:;"
                          @click="EmployeePerformance(items.id, items.nik)"
                          ><i class="ti ti-pulse text-primary"></i>
                          Performance</a
                        >
                      </li>
                      <li v-if="$store.state.app.access.includes('update')">
                        <a href="javascript:;" @click="EmployeeUpdate(items.id)"
                          ><i class="ti ti-pencil text-success"></i> Update</a
                        >
                      </li>
                      <li
                        v-if="
                          $store.state.app.access.includes('resign') &&
                          items.activity_status !== 'resign' &&
                          items.join_contract_status == 'no'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="EmployeeResign(items.nik)"
                          ><i class="ti ti-hand-stop text-warning"></i>
                          Resign</a
                        >
                      </li>
                      <li
                        v-if="
                          $store.state.app.access.includes('delete') &&
                          items.join_contract_status == 'no'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="EmployeeRemove(items.nik)"
                          ><i class="ti ti-trash text-danger"></i> Remove</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
                <td class="text-center">
                  <img
                    :src="$store.state.api_resource + items.photo"
                    class="table-picture-profile"
                    v-show="items.photo !== null"
                  />
                  <div class="table-avatar" v-show="items.photo == null"></div>
                </td>
                <td>
                  <span :class="items.activity_status | activityStatus">{{
                    items.activity_status
                  }}</span>
                </td>
                <td width="120">
                  # {{ items.nik }}<br />
                  <span class="text-secondary">{{ items.branch_name }}</span>
                </td>
                <td width="200">
                  <span
                    :class="
                      items.contract_status == 'active'
                        ? 'text-bold'
                        : 'text-success'
                    "
                    >{{
                      items.join_contract_status == "yes"
                        ? items.client_name
                        : "Free / Available"
                    }}</span
                  >
                  <br /><small class="text-secondary"
                    >{{ items.contract_start_date | moment("DD/MM/YYYY") }} --
                    {{ items.contract_end_date | moment("DD/MM/YYYY") }}</small
                  >
                </td>
                <td>{{ items.firstname }} {{ items.lastname }}</td>
                <td>{{ items.job_title }}</td>
                <td>{{ items.email }}</td>
                <td>{{ items.phone_number }}</td>
                <td>
                  {{ items.identity_card }}<br />
                  {{ items.identity_number }}
                </td>
                <td>{{ items.nationality }}</td>
                <td>{{ items.marital_status }}</td>
                <td>{{ items.gender }}</td>
                <td>{{ items.birth_place }}</td>
                <td>{{ items.birth_date }}</td>
                <td>{{ items.nama_provinsi }}</td>
                <td>{{ items.nama_kota }}</td>
                <td>{{ items.nama_kecamatan }}</td>
                <td width="250">{{ items.address }}</td>
                <td width="350">{{ items.individual_skill }}</td>
                <td>{{ items.created_at | moment("DD/MM/YYYY - h:mm:ss") }}</td>
                <td>
                  {{
                    items.updated_at == null
                      ? "----"
                      : items.updated_at | moment("DD/MM/YYYY - h:mm:ss")
                  }}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="22">
                  <div class="no-data" v-if="!$store.state.loading.status">
                    Sorry, data is not available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(data_model.last_page)"
            :click-handler="tableQuery"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import router from "../../router";
import PopupDetail from "../Employment/@popup_detail";
import PopupPerformance from "../Employment/@popupPerformance";
import SelectBranch from "../Branch/@SelectBranch";

import axios from "axios";

export default {
  components: {
    PopupDetail,
    PopupPerformance,
    SelectBranch,
  },
  data() {
    return {
      // modal import
      fileImport: null,
      modal_import_filter: {
        jobposition_id: "",
        provinces_id: null,
        provinces_name: null,
        city_id: "",
        district_id: "",
        model_job_position: [],
        model_city: [],
        model_districts: [],
      },

      isRoot: false,
      isClient: false,

      meta_url: "employee",
      isEnablePopup: false,
      isEnableTools: false,
      isEnableOrder: false,
      filter_key: "",

      model_job_position: [],
      provinces_name: "",
      model_province: [],
      model_kota: [],
      model_kecamatan: [],
      meta_filter: {
        join_contract: "all",
        job_position: "all",
        gender: "all",
        activity_status: "all",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
        id_branch: "all",
      },
      meta_data: { okey: "employee.id", oval: "desc", skey: "", sval: "" },
      download: { fields: "", data: "", title: "", meta: "" },
    };
  },

  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
    }),
  },
  created() {
    this.QueryJobPosition();

    // filter branch
    if (localStorage.getItem("userPrivilegesName") !== "root") {
      this.isClient =
        localStorage.getItem("userIdPrivileges") == 14 ? true : false;
      this.meta_filter.id_branch = localStorage.getItem("userBranchId");
      this.meta_filter.id_propinsi = localStorage.getItem("id_provinces");
      this.provinces_name = localStorage.getItem("provinces_name");
      this.fetchKota();
      this.tableQuery();
    } else {
      this.isRoot = true;
      this.fetchProvinces();
      this.tableQuery();
    }
  },
  methods: {
    processBranch(data) {
      this.meta_filter.id_branch = data;
      this.tableQuery();
    },
    closePopup(initial) {
      this.isEnablePopup = false;
      if (initial) {
        this.tableQuery();
      }
    },
    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },
    FilterProvinces() {
      this.tableQuery();
      this.fetchKota();
    },
    async fetchProvinces() {
      if (this.isRoot) {
        this.meta_filter.id_kota = "all";
        this.meta_filter.id_kecamatan = "all";

        await axios
          .get("location/propinsi/data")
          .then((response) => {
            this.model_province = response.data;
            this.model_kota = [];
          })
          .catch((error) => {
            console.log(error.statusText);
          })
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      }
    },

    async fetchKota() {
      if (this.isRoot) {
        await axios
          .post("location/kota/data", {
            where: { key: "provinsi_id", value: this.meta_filter.id_propinsi },
            order: { key: "id", value: "asc" },
          })
          .then((response) => {
            this.model_kota = response.data;
          })
          .catch((error) => {})
          .finally(() => {
            this.$store.state.loading.status = false;
          });
      } else {
        this.model_kota = JSON.parse(localStorage.getItem("data_regencies"));
      }
    },
    async fetchKotaModal() {
      await axios
        .post("location/kota/data", {
          where: {
            key: "provinsi_id",
            value: this.modal_import_filter.provinces_id,
          },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.modal_import_filter.model_city = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loading.status = false;
        });
      // if (this.isRoot) {
      //   await axios
      //     .post("location/kota/data", {
      //       where: {
      //         key: "provinsi_id",
      //         value: this.modal_import_filter.provinces_id,
      //       },
      //       order: { key: "id", value: "asc" },
      //     })
      //     .then((response) => {
      //       this.modal_import_filter.model_city = response.data;
      //     })
      //     .catch((error) => {})
      //     .finally(() => {
      //       this.$store.state.loading.status = false;
      //     });
      // } else {
      //   this.model_kota = JSON.parse(localStorage.getItem("data_regencies"));
      // }
    },
    async fetchKecamatanModal() {
      // console.log("fetchKecamatanModal")
      await axios
        .post("location/kecamatan/data", {
          where: { key: "kota_id", value: this.modal_import_filter.city_id },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.modal_import_filter.model_districts = response.data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    async fetchKecamatan() {
      this.meta_filter.id_kecamatan = "all";
      await axios
        .post("location/kecamatan/data", {
          where: { key: "kota_id", value: this.meta_filter.id_kota },
          order: { key: "id", value: "asc" },
        })
        .then((response) => {
          this.model_kecamatan = response.data;
          this.tableQuery();
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    async QueryJobPosition() {
      await axios
        .get("job_position/all")
        .then((res) => {
          this.model_job_position = res.data;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
          this.isEnableEquipment = true;
        });
    },
    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("Please enter the keyword");
        return;
      }
      this.tableQuery();
    },

    tableRefresh() {
      this.meta_filter = {
        job_position: "all",
        gender: "all",
        activity_status: "all",
        id_propinsi: "all",
        id_kota: "all",
        id_kecamatan: "all",
      };
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.$store.state.loading.msg = "Refreshing Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", { url: this.meta_url + "/data" });
    },
    DownloadData() {
      this.$store.state.loading.msg = "Download Report Data...";
      this.$store.state.loading.status = true;
      axios
        .post(this.meta_url + "/download")
        .then((res) => {
          this.download.fields = {
            Nik: "nik",
            Firstname: "firstname",
            Lastname: "lastname",
            Email: "email",
            "Phone Number": "phone_number",
            "Identity Card": "identity_card",
            "Identity Number": "identity_number",
            Nationality: "nationality",
            "Marital Status": "marital_status",
            gender: "gender",
            "Birth Place": "birth_place",
            "Birth Date": "birth_date",
            Propinsi: "nama_provinsi",
            "Nama Kota": "nama_kota",
            "Nama Kecamatan": "nama_kecamatan",
            Address: "address",
            Sallary: "sallary",
            "Individual Skill": "individual_skill",
            "Created Date": "created_at",
            "Updated Date": "updated_at",
          };

          this.download.title = "Report Employee";
          this.download.data = res.data;
          this.isEnablePopup = "download";
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    modalImportExcel() {
      this.modal_import_filter.model_job_position = this.model_job_position;
      this.modal_import_filter.provinces_id =
        localStorage.getItem("id_provinces");
      this.modal_import_filter.provinces_name =
        localStorage.getItem("provinces_name");
      this.fetchKotaModal();
      this.isEnablePopup = "modal-import-excel";
    },
    async getSampleDownloadFormatEmployee() {
      let data = {
        branch_id: localStorage.getItem("userBranchId"),
        jobposition_id: this.modal_import_filter.jobposition_id,
        provinces_id: this.modal_import_filter.provinces_id,
        city_id: this.modal_import_filter.city_id,
        district_id: this.modal_import_filter.district_id,
      };
      await axios
        .post(
          "employee/export-sample-employee",
          {
            data: data,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          const link = document.createElement("a");

          link.href = window.URL.createObjectURL(new Blob([res.data]));

          link.setAttribute("download", "master employee.xlsx");

          document.body.appendChild(link);
          link.click();
        });
    },
    tableQuery(pageNum) {
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/data",
        offset: this.page,
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        filter: [
          {
            key: "employee.join_contract_status",
            val: this.isClient ? "yes" : this.meta_filter.join_contract,
          },
          // { key: "contract.status", val: "on_going" },
          { key: "id_job_position", val: this.meta_filter.job_position },
          { key: "gender", val: this.meta_filter.gender },
          { key: "activity_status", val: this.meta_filter.activity_status },
          { key: "employee.id_propinsi", val: this.meta_filter.id_propinsi },
          { key: "employee.id_kota", val: this.meta_filter.id_kota },
          { key: "employee.id_kecamatan", val: this.meta_filter.id_kecamatan },
          { key: "employee.id_branch", val: this.meta_filter.id_branch },
        ],
      };
      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", data);
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    async EmployeeResign(nik) {
      if (confirm("Sure you want to change the employee status to resign ?")) {
        let model = { where: { key: "employee.nik", value: nik } };
        let data = { url: "employee/resign", form_data: model };
        let res = await this.$store.dispatch("crud/storeData", data);
        if (res && res.result == "ok") {
          this.tableQuery();
        }
      }
    },
    async EmployeeRemove(nik) {
      if (confirm("Are you sure want to remove this data ?")) {
        this.$store.state.loading.msg = "Removing Data...";
        this.$store.state.alert.type = "trash";
        let model = { where: { key: "employee.nik", value: nik } };
        let data = { url: this.meta_url + "/destroy", form_data: model };
        let res = await this.$store.dispatch("crud/storeData", data);
        if (res && res.result == "ok") {
          this.tableQuery();
        }
      }
    },
    EmployeeDetail(id) {
      localStorage.setItem("QUERY_ID", id);
      this.isEnablePopup = "detail";
    },
    EmployeePerformance(id, nik) {
      localStorage.setItem("QUERY_ID", id);
      localStorage.setItem("QUERY_NIK", nik);
      this.isEnablePopup = "performance";
    },
    EmployeeUpdate(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: this.meta_url + "/update" });
    },

    async processFile(event) {
      this.$store.state.loading.msg = "Processing Import Data...";
      this.$store.state.loading.status = true;

      this.fileImport = event.target.files[0];
      // console.log(this.fileImport);
      let formData = new FormData();
      formData.append("file", this.fileImport);

      await axios
        .post("employee/import-excel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.closePopup();

          if (res.data.result == "ok") {
            // console.log("betur");
            this.$store.state.alert.status = true;
            this.$store.state.alert.msg = res.data.msg;
            // router.push({ name: this.meta_url + "/data" });
          } else {
            // console.log("error kesini");
            console.log(res.data.msg);
            this.$store.state.alert.status = true;
            this.$store.state.alert.type = "trash";
            this.$store.state.alert.msg = res.data.msg;
          }
        })
        .catch((err) => {
          this.closePopup();
          let error = Object.assign({}, err);

          this.$store.state.alert.status = true;
          this.$store.state.alert.type = "trash";
          this.$store.state.alert.msg = error.response.statusText;
        })
        .finally(() => {
          this.closePopup();
          this.$store.state.loading.status = false;
          this.$store.state.loading.status = false;
        });
    },
  },
};
</script>
