<style lang="scss"> @import "assets/style/core.scss";</style>

<template>
    <div>
        <Login v-if="!isUserLogin && !isForgotPassword"/>
        <div v-if="isUserLogin">
            <SetupModule v-on:start="startApp" v-if="!isSetupModule"/>
            <TopNavigation/>
            <main role="main" class="container">
               
                <PopupMsg v-if="$store.state.alert.status"/>
                <Breadcrumb/>
                <LoaderApp />
                <router-view/>
                <!--<div class="copyright">
                    Copyrights &copy;  2020 <span class="green"> PT.Ecocare Indonesia</span> All Rights Reserved.
                </div>-->
            </main>
        </div>  
    </div>
</template>

<script>
import $ from 'jquery';
import Login from '@/application/Auth/Login.vue';
import SetupModule from '@/application/Auth/@SetupModule.vue';
import TopNavigation from './components/templates/TopNavigation';
import Breadcrumb from './components/templates/Breadcrumb-App';
import LoaderApp from './components/parts/Loader-App';
import PopupMsg from './components/parts/PopupMessage';


export default 
{
    components: {
        TopNavigation,
        Breadcrumb,
        LoaderApp,
        Login,
        SetupModule,
        PopupMsg,
        LoaderApp
    },
    
    data(){
        return {
            isSetupModule : localStorage.getItem('app-start'),
            isUserLogin : false,
            isForgotPassword : false,
        }
    },
    mounted() {
       
    },
    created() 
    {
        if( localStorage.getItem('userid') && localStorage.getItem('jwt-token') )
        {
            this.isUserLogin = true;

        }else{
            if( this.$route.name == 'forgot' ){
                // check valid link
                this.isForgotPassword = true;
            }else{
                this.isUserLogin = false;
            }   
        }
    },
    
    methods:
    {
        startApp()
        {
            $('#initial-module').fadeOut('slow');
            localStorage.setItem('app-start', true);
            window.location.reload();
        },

        init_browser_style()
        {
            var width = window.innerWidth
            || document.documentElement.clientWidth
            || document.body.clientWidth;
            let main_window_width = width - this.css_sidebar_width;
            $('.main-content').css('width', main_window_width);
        },

    }
}
</script>


<style lang="scss" scoped>


.copyright{
    width:100%;
    text-align: center;
    font-size:11px;
    color:#b7b7b7;
    position: relative;
    margin-top:280px;
    .green{
        font-size:inherit;
        color:#0034bf;
    }
}

#msg-system{
    float:left;
    width:100%;
    padding:10px;
    padding-top:13px;
    position: relative;
    top:-20px;
    border-radius:5px;
    -moz-border-radius:5px;
    -webkit-border-radius:5px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);
}
.sys-msg-warning{
    background-color:#e2ffa9;
}
.sys-msg-danger{
    background-color:#ffd3d3;
}
</style>