<template>
  <div class="col-md-12 p-0">
    <PopupDetailContract
      :id_contract="id_contract"
      v-on:exit="PopupClose"
      v-if="isEnablePopupDetail == 'detail'"
    />
    <PopupContractTerminate
      :id_contract="id_contract"
      v-on:exit="PopupClose"
      v-if="isEnablePopupDetail == 'terminate'"
    />
    <div class="popup popup-sm" v-if="isEnablePopup">
      <div class="content">
        <small id="close-popup" @click="isEnablePopup = false"
          ><i class="ti ti-close"></i
        ></small>
        <h5 class="text-left">
          Remove Data<br /><br />
          <p class="p-0 m-0">
            Data will be permanently deleted, Are you sure want to remove the
            data ?
          </p>
          <span class="text-secondary"
            >Please choose the option to delete data</span
          >
          <hr />
        </h5>
        <div class="text-right">
          <a
            href="javascript:;"
            @click="isEnablePopup = false"
            class="btn btn-secondary"
            >Cancel</a
          >
          &nbsp; &nbsp;
          <a href="javascript:;" @click="removeData()" class="btn btn-warning"
            >Remove</a
          >
          <!-- &nbsp;OR&nbsp;
          <a
            href="javascript:;"
            @click="removeData('contract')"
            class="btn btn-primary"
            >Contract Only</a
          > -->
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="table-header">
          <h5>
            Master Data <br />
            <small
              >Total Records :
              <b>{{ data_model.total | formatPrice }}</b></small
            >
          </h5>

          <div class="group-search pull-right">
            <a class="btn" @click="tableRefresh()"
              ><i class="ti ti-reload"></i
            ></a>
            <a class="btn" @click="isActiveSearch()"
              ><i
                :class="isEnableTools == false ? 'ti ti-search' : 'ti ti-close'"
              ></i
            ></a>
            <div class="select" v-if="isEnableTools">
              <select v-model="meta_data.skey" @change="meta_data.sval = ''">
                <option value="">-- Choose Field --</option>
                <option value="contract.contract_number">
                  Contract Number
                </option>
                <option value="contract.description">Description</option>
                <option value="client.name">Client Name</option>
                <option value="client.office_address">Client Address</option>
              </select>
            </div>
            <div class="group" v-if="isEnableTools">
              <input
                type="date"
                v-model="meta_data.sval"
                v-if="
                  meta_data.skey == 'created_at' ||
                  meta_data.skey == 'updated_at'
                "
              />
              <input
                type="text"
                v-model="meta_data.sval"
                placeholder="Enter Keyword ...."
                v-else
              />
              <a class="btn" @click="tableSearch()"> SEARCH DATA </a>
            </div>
            <router-link
              :to="{ name: 'jobcon/contract-store' }"
              class="btn btn-primary"
              v-if="$store.state.app.access.includes('insert')"
              ><i class="ti ti-plus"></i> Create New</router-link
            >
            <!-- <a @click="syncData()" class="btn btn-warning" v-if="isRoot">
              <i class="ti ti-reload"></i> Sync Data
            </a> -->
          </div>
          <div class="group-filter" v-if="!isClient">
            <div class="row">
              <!-- <div class="col-md-3">
                <label class="text-secondary">Start Date</label><br />
                <div class="form-group mr-2">
                  <span class="text-secondary">Year</span>
                  <select
                    class="form-control"
                    v-model="meta_filter.sd_year"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Year --</option>
                    <option
                      v-for="(items, idx) in data_model.start_year"
                      :key="idx"
                      :value="items.YEAR"
                    >
                      {{ items.YEAR }}
                    </option>
                  </select>
                </div>
                <div class="form-group mr-2">
                  <span class="text-secondary">Month</span>
                  <select
                    class="form-control"
                    v-model="meta_filter.sd_month"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Month --</option>
                    <option
                      v-for="(items, idx) in data_model.start_month"
                      :key="idx"
                      :value="items.MONTH"
                    >
                      {{ items.MONTH | convertStringMonth }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <label class="text-secondary">End Date</label><br />
                <div class="form-group mr-2">
                  <span class="text-secondary">Year</span>
                  <select
                    class="form-control"
                    v-model="meta_filter.ed_year"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Year --</option>
                    <option
                      v-for="(items, idx) in data_model.end_year"
                      :key="idx"
                      :value="items.YEAR"
                    >
                      {{ items.YEAR }}
                    </option>
                  </select>
                </div>
                <div class="form-group mr-2">
                  <span class="text-secondary">Month</span>
                  <select
                    class="form-control"
                    v-model="meta_filter.ed_month"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Month --</option>
                    <option
                      v-for="(items, idx) in data_model.end_month"
                      :key="idx"
                      :value="items.MONTH"
                    >
                      {{ items.MONTH | convertStringMonth }}
                    </option>
                  </select>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-group mr-3">
                  <label class="text-secondary">Choose Date Range</label><br />
                  <div class="mt-3">
                    <date-picker
                      v-model="period"
                      value-type="format"
                      type="date"
                      format="YYYY-MM-DD"
                      placeholder="Pilih Range Tanggal"
                      width="100%"
                      @change="tableQuery()"
                      range
                      confirm
                    >
                      <template v-slot:header="{ emit }">
                        <div class="row text-left">
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectLastDay(emit, 3)"
                            >
                              3 hari sebelumnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectLastDay(emit, 7)"
                            >
                              7 hari sebelumnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectLastDay(emit, 30)"
                            >
                              30 hari sebelumnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectLastDay(emit, 60)"
                            >
                              60 hari sebelumnya
                            </button>
                          </div>
                        </div>
                      </template>
                      <template v-slot:footer="{ emit }">
                        <div class="row text-left mb-2">
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectNextDay(emit, 3)"
                            >
                              3 hari selanjutnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectNextDay(emit, 7)"
                            >
                              7 hari selanjutnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectNextDay(emit, 30)"
                            >
                              30 Hari selanjutnya
                            </button>
                          </div>
                          <div class="col-md-3">
                            <button
                              class="mx-btn mx-btn-text"
                              @click="selectNextDay(emit, 60)"
                            >
                              60 Hari selanjutnya
                            </button>
                          </div>
                        </div>
                      </template>
                    </date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group float-right" v-if="isRoot">
                  <br />
                  <label>Branch Station</label>
                  <SelectBranch v-on:processBranch="processBranch" />
                </div>
                <div class="form-group float-right mr-2">
                  <br />
                  <label class="text-secondary">Contract Status</label>
                  <select
                    class="form-control"
                    v-model="meta_filter.status"
                    @change="tableQuery()"
                  >
                    <option value="all">-- All Contract Status --</option>
                    <option value="draft">Draft</option>
                    <!-- <option value="complete">Complete</option> -->
                    <option value="on_going">On Going</option>
                    <option value="terminate">Terminate</option>
                    <option value="finished">Finished</option>
                    <!-- <option value="out_of_date">Out of Date</option> -->
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-cover">
          <table class="table-hover" style="min-width: 1900px">
            <thead>
              <tr>
                <th>No</th>
                <th
                  width="70"
                  class="no-filter text-center"
                  v-if="JSON.parse($store.state.app.access).length > 1"
                >
                  Action
                </th>
                <th
                  :class="meta_data.okey == 'client.name' ? 'active' : ''"
                  v-on:click="tableOrdering('client.name')"
                >
                  Client Name
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.contract_number' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.contract_number')"
                >
                  Contract Number
                </th>
                <th
                  :class="meta_data.okey == 'contract.status' ? 'active' : ''"
                  v-on:click="tableOrdering('contract.status')"
                >
                  Contract Status
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.start_date' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.start_date')"
                >
                  Contract Period
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.man_power' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.man_power')"
                >
                  MP Request
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.description' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.description')"
                >
                  Description
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.contract_date' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.contract_date')"
                >
                  Contract Date
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.created_at' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.created_at')"
                >
                  Created Date
                </th>
                <th
                  :class="
                    meta_data.okey == 'contract.updated_at' ? 'active' : ''
                  "
                  v-on:click="tableOrdering('contract.updated_at')"
                >
                  Updated Date
                </th>
              </tr>
            </thead>
            <tbody v-if="data_model.total > 0">
              <tr v-for="(items, idx) in data_model.data" :key="idx">
                <td>{{ idx + data_model.from }}</td>
                <td v-if="JSON.parse($store.state.app.access).length > 1">
                  <div class="tools">
                    <a href="javascript:;">Tools</a>
                    <ul>
                      <li style="display: flex">
                        <a
                          href="javascript:;"
                          @click="OpenContractPopup(items.id, 'detail')"
                          ><i class="text-primary ti ti-file"></i> Detail
                          Contract</a
                        >
                      </li>
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('terminate') &&
                          (items.status == 'draft' ||
                            items.status == 'complete' ||
                            items.status == 'on_going')
                        "
                      >
                        <a href="javascript:;" @click="editContract(items.id)"
                          ><i class="text-warning ti ti-pencil"></i> Edit
                          Contract</a
                        >
                      </li>
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('assign') &&
                          items.status !== 'terminate' &&
                          items.status !== 'finished'
                        "
                      >
                        <a href="javascript:;" @click="AssignOfficer(items.id)"
                          ><i class="text-success ti ti-user"></i> Manage -
                          Officer</a
                        >
                      </li>
                      <!-- <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('task') &&
                          items.status !== 'terminate' &&
                          items.status !== 'finished'
                        "
                      >
                        <a href="javascript:;" @click="AssignJobTask(items.id)"
                          ><i class="text-success ti ti-layout"></i> Manage -
                          Section Room
                        </a>
                      </li> -->
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('task') &&
                          items.status !== 'terminate' &&
                          items.status !== 'finished'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="AssignJobTaskNew(items.id)"
                          ><i class="text-success ti ti-layout"></i> Manage -
                          Section Room
                          <!-- V2 -->
                        </a>
                      </li>
                      <!-- <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('shift') &&
                          items.status !== 'terminate' &&
                          items.status !== 'finished'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="AssignShiftWorker(items.id)"
                          ><i class="text-success ti ti-time"></i> Manage -
                          Schedule Shift Worker</a
                        >
                      </li> -->
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('shift') &&
                          items.status !== 'terminate' &&
                          items.status !== 'finished'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="AssignShiftWorkerV2(items.id)"
                          ><i class="text-success ti ti-time"></i> Manage -
                          Schedule Shift Worker
                          <!-- V2 -->
                        </a>
                      </li>
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('terminate') &&
                          (items.status == 'on_going' ||
                            items.status == 'finished')
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="OpenContractPopup(items.id, 'terminate')"
                          ><i class="text-warning ti ti-lock"></i> Terminate
                          Contract</a
                        >
                      </li>
                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('terminate') &&
                          items.renewal_id == null &&
                          items.status == 'finished'
                        "
                      >
                        <a
                          href="javascript:;"
                          @click="editContractRenewal(items.id)"
                          ><i class="text-warning ti ti-pencil"></i> Renewal</a
                        >
                      </li>

                      <li
                        style="display: flex"
                        v-if="
                          $store.state.app.access.includes('delete') &&
                          items.status == 'draft'
                        "
                      >
                        <a href="javascript:;" @click="preparationRemmove(idx)"
                          ><i class="text-danger ti ti-trash"></i> Remove</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
                <td>
                  <b>{{ items.client_name }}</b>
                </td>
                <td width="160">{{ items.contract_number }}</td>
                <td>
                  <span :class="items.status | contractStatus">
                    {{ items.status | cleanString }}</span
                  >
                </td>
                <td width="200">
                  {{ items.start_date | moment("YYYY-MM-DD") }} -
                  {{ items.end_date | moment("YYYY-MM-DD") }}
                </td>
                <td class="pr-3" width="400">
                  <span
                    class=""
                    v-for="(initems, index) in JSON.parse(items.man_power)"
                    :key="index"
                  >
                    {{ initems.total }} ( {{ initems.job }} )
                    <span v-if="index % 2 == 0">,</span>
                  </span>
                </td>
                <td width="400">
                  {{ items.description !== null ? items.description : "....." }}
                </td>
                <td width="120">
                  {{ items.contract_date | moment("DD/MM/YYYY") }}
                </td>
                <td>{{ items.created_at | moment("DD/MM/YYYY") }}</td>
                <td>{{ items.updated_at | moment("DD/MM/YYYY") }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="11">
                  <div class="no-data" v-if="!$store.state.loading.status">
                    Sorry data is not available
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="pagination">
          <small class="info"
            ><i class="ti ti-split-h"></i> Scroll horizontal to view
            data.</small
          >
          <paginate
            :page-count="parseInt(data_model.last_page)"
            :click-handler="tableQuery"
            :container-class="'pagination'"
            :page-class="'page-item'"
          >
          </paginate>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import router from "../../../router";
import PopupDetailContract from "../@PopupDetailContract";
import PopupContractTerminate from "../@PopupContractTerminate";
import SelectBranch from "../../Branch/@SelectBranch";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/id";

import { mapState } from "vuex";

export default {
  data() {
    return {
      isClient: false,
      isRoot: false,
      isEnablePopupDetail: false,
      isEnablePopup: false,
      isEnableTools: false,
      isEnableOrder: false,
      id_contract: null,
      date_range: "",
      // period: ["2021-09-01", "2021-09-16"],
      period: [
        new Date(new Date().setFullYear(new Date().getFullYear() - 2))
          .toISOString()
          .split("T")[0],
        new Date(new Date().setFullYear(new Date().getFullYear() + 2))
          .toISOString()
          .split("T")[0],
      ],
      active_selected_id_contract: "",
      active_selected_id_client: "",
      meta_url: "contract",
      meta_data: {
        okey: "created_at",
        oval: "desc",
        wkey: "",
        wval: "",
        skey: "",
        sval: "",
      },
      meta_filter: {
        status: "all",
        sd_year: "all",
        sd_month: "all",
        ed_year: "all",
        ed_month: "all",
        id_branch: "all",
      },
    };
  },
  components: {
    PopupDetailContract,
    PopupContractTerminate,
    SelectBranch,
    DatePicker,
  },
  computed: {
    ...mapState("crud", {
      data_model: (state) => state.data_model,
      msg_error: (state) => state.error,
    }),
  },
  created() {
    // filter branch
    if (localStorage.getItem("userPrivilegesName") !== "root") {
      this.isClient =
        localStorage.getItem("userIdPrivileges") == 14 ? true : false;
      this.meta_filter.id_branch = localStorage.getItem("userBranchId");
    } else {
      this.isRoot = true;
    }

    this.tableQuery();
  },
  methods: {
    selectLastDay(emit, range) {
      const start = new Date();
      const end = new Date();
      start.setTime(start.getTime() - range * 24 * 3600 * 1000);
      const date = [start, end];
      emit(date);
    },
    selectNextDay(emit, range) {
      const start = new Date();
      const end = new Date();
      end.setTime(end.getTime() + range * 24 * 3600 * 1000);
      const date = [start, end];
      emit(date);
    },
    processBranch(data) {
      this.meta_filter.id_branch = data;
      this.tableQuery();
    },
    PopupClose(initial) {
      this.isEnablePopupDetail = false;
      if (initial) {
        this.tableQuery();
      }
    },
    OpenContractPopup(id, initial) {
      this.id_contract = id;
      this.isEnablePopupDetail = initial;
    },

    isActiveSearch() {
      this.isEnableTools = !this.isEnableTools;
      if (this.isEnableTools) this.meta_data.sval = "";
    },

    tableSearch() {
      if (this.meta_data.skey == "") {
        alert("🙏, Please choose target field");
        return;
      }
      if (this.meta_data.sval == "") {
        alert("🙏, Please enter the keyword");
        return;
      }
      this.tableQuery();
    },
    tableOrdering(key) {
      this.isEnableOrder = !this.isEnableOrder;
      this.meta_data.okey = key;
      this.meta_data.oval = this.isEnableOrder ? "ASC" : "DESC";
      this.tableQuery(this.page);
    },
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      let newDate = [year, month, day].join("-");

      return newDate;
    },
    tableQuery(pageNum) {
      // console.log(pageNum);
      // { key: "contract.start_date", val: this.period[0] },
      //     { key: "contract.end_date", val: this.period[1] },
      this.page = pageNum;
      let data = {
        url: this.meta_url + "/data",
        offset: this.page,
        // daterange: { start: this.date_range[0], end: this.date_range[1] },
        search: { key: this.meta_data.skey, value: this.meta_data.sval },
        order: { key: this.meta_data.okey, value: this.meta_data.oval },
        where: { key: this.meta_data.wkey, value: this.meta_data.wval },
        period_start: this.period[0],
        period_end: this.period[1],
        filter: [
          { key: "contract.status", val: this.meta_filter.status },
          // { key: "sd_year", val: this.meta_filter.sd_year },
          // { key: "sd_month", val: this.meta_filter.sd_month },
          // { key: "ed_year", val: this.meta_filter.ed_year },
          // { key: "ed_month", val: this.meta_filter.ed_month },
          { key: "contract.id_branch", val: this.meta_filter.id_branch },
          //{key : 'contract-station', val:localStorage.getItem('userContract')}
        ],
      };

      this.$store.state.loading.msg = "Processing Fetching Data...";
      this.$store.state.loading.status = true;
      this.$store.dispatch("crud/getData", data);
    },
    syncData() {
      this.$store.state.loading.msg = "Process Synchronization ...";
      this.$store.state.loading.status = true;

      axios
        .get(this.meta_url + "/sync-data")
        .then((res) => {
          // console.log(res);
          let response = res.data;
          this.$store.state.loading.status = true;
          this.$store.state.loading.msg = response.msg;
        })
        .finally(() => {
          this.$store.state.loading.status = false;
        });
    },
    tableRefresh() {
      this.meta_data.skey = "";
      this.meta_data.sval = "";
      this.$store.state.loading.msg = "Refreshing Data...";
      this.$store.state.loading.status = true;
      // this.$store.dispatch("crud/getData", { url: this.meta_url + "/data" });
      this.tableQuery();
    },
    tableCreateData() {
      this.model_form = {};
      this.mode_form = "store";
    },
    tableUpdate(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/contract-update" });
    },
    AssignOfficer(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/contract-assign" });
    },
    AssignShiftWorker(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/shift" });
    },
    AssignShiftWorkerV2(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/shift-v2" });
    },
    AssignJobTask(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/task" });
    },
    AssignJobTaskNew(id) {
      localStorage.setItem("QUERY_ID", id);
      router.push({ name: "jobcon/task-section" });
    },
    preparationRemmove(idx) {
      let model = this.data_model.data[idx];
      this.active_selected_id_contract = model.id;
      this.active_selected_id_client = model.id_client;
      this.isEnablePopup = true;
    },
    async removeData(initial) {
      this.$store.state.loading.msg = "Processing Remove Data Contract...";
      this.$store.state.loading.status = true;

      let data = {
        url: this.meta_url + "/destroy/" + this.active_selected_id_contract,
      };
      let res = await this.$store.dispatch("crud/deleteData", data);

      if (res && res.result == "ok") {
        this.$store.state.alert.status = true;
        this.$store.state.alert.msg = res.msg;
        this.tableQuery();
        this.isEnablePopup = false;
        // if (initial == "contract&client") {
        //   this.$store.state.loading.msg = "Processing Remove Data Client...";
        //   this.$store.state.loading.status = true;

        //   let data = {
        //     url: "client/master-destroy/" + this.active_selected_id_client,
        //     disableAlert: true,
        //   };
        //   let res = await this.$store.dispatch("crud/deleteData", data);
        //   this.$store.state.alert.status = true;
        //   if (res && res.result == "ok") {
        //     this.$store.state.alert.msg = res.msg;
        //   } else {
        //     this.$store.state.alert.msg =
        //       "Successfully only remove data contract, Cannot remove Data client. This client is still use in another contract";
        //   }

        //   this.isEnablePopup = false;
        //   this.tableQuery();
        // } else {
        //   this.$store.state.alert.status = true;
        //   this.$store.state.alert.msg = res.msg;

        //   this.tableQuery();
        //   this.isEnablePopup = false;
        // }
      } else {
        this.isEnablePopup = false;
        this.$store.state.alert.status = false;
        setTimeout(() => {
          this.$store.state.alert.type = "warning";
          this.$store.state.alert.msg = res.msg;
          this.$store.state.alert.status = true;
        }, 300);
      }
    },
    editContract(id) {
      localStorage.setItem("QUERY_ID", id);
      this.$router.push({ name: "jobcon/contract-edit" });
    },
    editContractRenewal(id) {
      localStorage.setItem("QUERY_ID", id);
      this.$router.push({ name: "jobcon/contract-renewal" });
    },
  },
};
</script>
<style scoped>
.mx-range-wrapper {
  width: 630px !important;
}
</style>
